import { render, staticRenderFns } from "./vehicle.vue?vue&type=template&id=3d1164be&scoped=true&"
import script from "./vehicle.vue?vue&type=script&lang=js&"
export * from "./vehicle.vue?vue&type=script&lang=js&"
import style0 from "./vehicle.vue?vue&type=style&index=0&id=3d1164be&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d1164be",
  null
  
)

export default component.exports