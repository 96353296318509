<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>车辆管理</el-breadcrumb-item>
      <el-breadcrumb-item>车辆列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片视图 -->
    <el-card shadow="never">
      <div class="change">
        <el-input v-model="queryInfo.carNumber"
                  clearable
                  placeholder="请输入车牌号"></el-input>
        <el-input v-model="queryInfo.driverName"
                  clearable
                  placeholder="请输入司机名称"></el-input>
        <el-input v-model="queryInfo.siteName"
                  clearable
                  placeholder="请输入站点名称"></el-input>
        <el-button type="primary"
                   @click="search">查询</el-button>
        <el-button>导出</el-button>
        <el-button type="primary"
                   class="add"
                   @click="addDialogVisible = true">新增</el-button>
      </div>
      <!-- 用户信息框 -->
      <el-table :data="vehicleList"
                border
                stripe
                style="width: 100%"
                :header-cell-style="{background:'rgba(22, 119, 255, 0.0300)'}">
        <el-table-column type="index"
                         align="center"> </el-table-column>
        <el-table-column prop="carNumber"
                         label="车牌号"
                         width="100"
                         align="center">
        </el-table-column>
        <el-table-column prop="carType"
                         label="车辆类型"
                         width="110"
                         align="center">
        </el-table-column>
        <el-table-column prop="carSource"
                         label="车辆来源"
                         width="105"
                         align="center">
          <template slot-scope="scope">{{map[scope.row.carSource]}}</template>
        </el-table-column>
        <el-table-column prop="brand"
                         label="品牌"
                         width="100"
                         align="center">
        </el-table-column>
        <el-table-column label="司机名称"
                         width="130"
                         align="center">
          <template slot-scope="scope">{{scope.row.driverName}}</template>
        </el-table-column>
        <el-table-column prop="driverAccount"
                         label="司机帐号"
                         width="170"
                         align="center">
        </el-table-column>
        <el-table-column label="站点名称"
                         width="145"
                         align="center">
          <template slot-scope="scope">{{scope.row.siteName}}</template>
        </el-table-column>
        <el-table-column prop="siteNum"
                         label="站点编号"
                         width="170"
                         align="center">
        </el-table-column>
        <el-table-column prop="line"
                         label="行驶路线"
                         align="center"
                         width="">
        </el-table-column>
        <el-table-column prop="flag"
                         label="状态"
                         width="80"
                         align="center">
          <template slot-scope="scope">{{map1[scope.row.flag]}}</template>
        </el-table-column>

        <el-table-column label="操作"
                         width="275"
                         align="center">
          <template slot-scope="scope">
            <el-button type="warning"
                       size="mini"
                       icon="el-icon-edit"
                       @click="edit(scope.row.id)">编辑</el-button>
            <el-button type="danger"
                       size="mini"
                       icon="el-icon-delete"
                       @click="removeCar(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页器 -->
      <el-pagination @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :current-page="queryInfo.current"
                     :page-sizes="[10,20,50,100,200]"
                     :page-size="queryInfo.size"
                     layout="total, sizes, prev, pager, next, jumper"
                     :total="total">
      </el-pagination>
    </el-card>
    <!-- 新增车辆对话框 -->
    <el-dialog title="新增车辆"
               :visible.sync="addDialogVisible"
               width="40%">
      <el-form :model="addForm"
               ref="addFormRef"
               label-width="80px"
               :rules="addFormRules">
        <el-form-item label="车牌号"
                      prop="carNumber">
          <el-input v-model="addForm.carNumber"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="车辆类型"
                      prop="carType">
          <el-input v-model="addForm.carType"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="品牌"
                      prop="brand">
          <el-input v-model="addForm.brand"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="站点编号"
                      prop="siteNum">
          <el-input v-model="addForm.siteNum"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="行驶路线"
                      prop="line">
          <el-input v-model="addForm.line"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="司机账号"
                      prop="driverAccount">
          <el-input v-model="addForm.driverAccount"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="司机名称"
                      prop="driverName">
          <el-select v-model="addForm.driverName"
                     @change="changeRegion"
                     placeholder="请选择司机">
            <el-option v-for="item in regionList"
                       :key="item.id"
                       :label="item.realName"
                       :value="item.realName">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="站点名称"
                      prop="siteName">
          <el-select v-model="addForm.siteName"
                     @change="changeSite"
                     placeholder="请选择站点">
            <el-option v-for="item in siteList"
                       :key="item.id"
                       :label="item.siteName"
                       :value="item.siteName">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车辆来源"
                      prop="carSource">
          <el-select v-model="addForm.carSource"
                     placeholder="请选择车辆来源">
            <el-option v-for="item in carList"
                       :key="item.id"
                       :label="item.label"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="primary"
                   @click="addCar">保 存</el-button>
        <el-button @click="addDialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 编辑车辆对话框 -->
    <el-dialog title="编辑车辆"
               :visible.sync="editDialogVisible"
               width="40%"
               @close="editCloseDialog">
      <el-form :model="editForm"
               ref="editFormRef"
               :rules="editFormRules"
               label-width="80px">
        <el-form-item label="车牌号"
                      prop="carNumber">
          <el-input v-model="editForm.carNumber"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="车辆类型"
                      prop="carType">
          <el-input v-model="editForm.carType"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="品牌"
                      prop="brand">
          <el-input v-model="editForm.brand"
                    clearable></el-input>
        </el-form-item>

        <el-form-item label="站点编号"
                      prop="siteNum">
          <el-input v-model="editForm.siteNum"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="行驶路线"
                      prop="line">
          <el-input v-model="editForm.line"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="司机账号"
                      prop="driverAccount">
          <el-input v-model="editForm.driverAccount"
                    clearable></el-input>
        </el-form-item>
        <el-form-item label="司机名称"
                      prop="driverName">
          <el-select v-model="editForm.driverName"
                     @change="changeRegion"
                     placeholder="选择司机">
            <el-option v-for="item in regionList"
                       :key="item.id"
                       :label="item.realName"
                       :value="item.realName">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="站点名称"
                      prop="siteName">
          <el-select v-model="editForm.siteName"
                     @change="changeSite"
                     placeholder="请选择站点">
            <el-option v-for="item in siteList"
                       :key="item.id"
                       :label="item.siteName"
                       :value="item.siteName">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车辆来源"
                      prop="carSource">
          <el-select v-model="editForm.carSource"
                     placeholder="选择车辆来源">
            <el-option v-for="item in carList"
                       :key="item.id"
                       :label="item.label"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态"
                      prop="flag">
          <el-select v-model="editForm.flag"
                     @focus="changeFlag()"
                     placeholder="请选择状态">
            <el-option v-for="item in map2"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button type="primary"
                   @click="editCar">保 存</el-button>
        <el-button @click="editDialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>
        <script>
export default {
  data() {
    return {
      // 控制添加车辆的显示与隐藏
      addDialogVisible: false,
      // 控制编辑车辆的显示与隐藏
      editDialogVisible: false,
      vehicleList: [],
      queryInfo: {
        keyword: '',
        carNumber: '',
        carSource: '',
        driverName: '',
        siteName: '',
        current: 1,
        size: 10,
      },
      total: 0,
      input: '',
      radio: '1',
      // 添加车辆表单数据
      addForm: {
        brand: '',
        carNumber: '',
        carSource: '',
        carType: '',
        driverAccount: '',
        driverName: '',
        line: '',
        siteName: '',
        siteNum: '',
      },
      // 添加表单的验证规则对象
      addFormRules: {
        carNumber: [
          { required: true, message: '请输入车牌号', trigger: 'blur' },
        ],
        carType: [
          { required: true, message: '请输入车辆类型', trigger: 'blur' },
        ],
        brand: [{ required: true, message: '请输入品牌', trigger: 'blur' }],
        siteNum: [
          { required: true, message: '请输入站点编号', trigger: 'blur' },
        ],
        line: [{ required: true, message: '请输入行驶路线', trigger: 'blur' }],
        driverAccount: [
          { required: true, message: '请输入司机账号', trigger: 'blur' },
        ],
        driverName: [
          { required: true, message: '请选择司机名称', trigger: 'change' },
        ],
        siteName: [
          { required: true, message: '请选择站点名称', trigger: 'change' },
        ],
        carSource: [
          { required: true, message: '请选择车辆来源', trigger: 'change' },
        ],
      },
      // 编辑表单的验证规则对象
      editFormRules: {
        carNumber: [
          { required: true, message: '请输入车牌号', trigger: 'blur' },
        ],
        carType: [
          { required: true, message: '请输入车辆类型', trigger: 'blur' },
        ],
        brand: [{ required: true, message: '请输入品牌', trigger: 'blur' }],
        siteNum: [
          { required: true, message: '请输入站点编号', trigger: 'blur' },
        ],
        line: [{ required: true, message: '请输入行驶路线', trigger: 'blur' }],
        driverAccount: [
          { required: true, message: '请输入司机账号', trigger: 'blur' },
        ],
        driverName: [
          { required: true, message: '请选择司机名称', trigger: 'change' },
        ],
        siteName: [
          { required: true, message: '请选择站点名称', trigger: 'change' },
        ],
        carSource: [
          { required: true, message: '请选择车辆来源', trigger: 'change' },
        ],
      },
      // 修改车辆表单数据
      editForm: {},
      // 车辆来源
      carList: [
        {
          id: 1,
          label: '站点所属',
        },
        {
          id: 2,
          label: '司机自带',
        },
      ],
      map: ['', '站点所属', '司机自带'],
      map1: ['禁用', '启用'],
      map2: [
        {
          value: 1,
          label: '启用',
        },
        {
          value: 0,
          label: '禁用',
        },
      ],
      // 司机列表
      regionList: [],
      //   站点选择器
      siteList: [],
      //   状态选择器
      flagList: [],
    }
  },
  created() {
    this.getVehicleList()
    this.changeRegion()
    this.changeSite()
  },
  methods: {
    // 获取车辆列表
    getVehicleList() {
      this.axios.post('/api/site/vehicle/page', this.queryInfo).then((res) => {
        // console.log(res)
        this.vehicleList = res.data.data.records
        this.total = res.data.data.total
      })
    },
    // 搜索框
    search() {
      this.queryInfo.current = 1
      this.getVehicleList
    },
    // 监听一页有几条数据
    handleSizeChange(newSize) {
      // console.log(newSize);
      this.queryInfo.size = newSize
      this.getVehicleList()
    },
    // 监听第几页
    handleCurrentChange(newPage) {
      // console.log(newPage);
      this.queryInfo.current = newPage
      this.getVehicleList()
    },
    // 司机选择器列表
    changeRegion() {
      this.axios.get('/api/site/user/getDispatcherList').then((res) => {
        // console.log(res)
        this.regionList = res.data.data
        this.regionList.forEach((item) => {
          // console.log(item)
          if (this.addForm.driverName === item.realName) {
            this.addForm.driverAccount = item.account
          } else if (this.editForm.driverName === item.realName) {
            this.editForm.driverAccount = item.account
          }
        })
      })
    },

    // 站点选择器列表
    changeSite() {
      this.axios.get('/api/site/info/getSiteList').then((res) => {
        // console.log(res)
        this.siteList = res.data.data
        this.siteList.forEach((item) => {
          //   console.log(item)
          if (this.addForm.siteName === item.siteName) {
            this.addForm.siteNum = item.siteNum
          } else if (this.editForm.siteName === item.siteName) {
            this.editForm.siteNum = item.siteNum
          }
        })
      })
    },
    // 状态选择器
    changeFlag() {
      this.axios
        .get(
          `/api/site/vehicle/setCarStatus?flag=${this.editForm.flag}&id=${this.editForm.id}`
        )
        .then((res) => {
          console.log(res)
          this.flagList = res.data.data
        })
    },

    // 添加车辆
    addCar() {
      this.$refs.addFormRef.validate((valid) => {
        // console.log(valid)
        if (!valid) return
        this.axios
          .post('/api/site/vehicle/addCarInfo', this.addForm)
          .then((res) => {
            // console.log(res)
            this.$message({
              message: '添加成功',
              type: 'success',
              duration: 1000,
            })
            // 重新获取车辆列表
            this.getVehicleList()
            // 点击确认关闭
            this.addDialogVisible = false
          })
      })
    },
    // 编辑用户信息
    edit(id) {
      this.editDialogVisible = true
      //   console.log(id)
      this.axios.get(`/api/site/vehicle/getCarInfo?id=${id}`).then((res) => {
        // console.log(res)
        this.editForm = res.data.data
      })
    },
    // 监听修改车辆对话框关闭重置
    editCloseDialog() {
      this.$refs.editFormRef.resetFields()
    },
    // 点击修改车辆
    editCar() {
      this.$refs.editFormRef.validate((valid) => {
        if (!valid) return
        // console.log(valid)
        this.axios
          .post('/api/site/vehicle/editCarInfo', this.editForm)
          .then((res) => {
            //   console.log(res)
            this.$message({
              message: '修改成功',
              type: 'success',
              duration: 1000,
            })
            // 重新获取用户列表
            this.getVehicleList()
            // 点击确认关闭
            this.editDialogVisible = false
          })
      })
    },
    // 根据id删除对应用户信息
    removeCar(id) {
      this.$confirm('此操作将永久删除该车辆, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.axios.get(`/api/site/vehicle/delCarInfo?id=${id}`)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.getVehicleList()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
  },
  // 搜索数据列表跟随变化
  watch: {
    queryInfo: {
      handler: function (newVal) {
        // console.log(newVal.queryInfo)
        this.getVehicleList()
      },
      deep: true,
    },
  },
}
</script>
<style scoped lang="less">
.el-breadcrumb {
  margin-bottom: 20px;
}

.el-table {
  margin-top: 20px;
}
.el-input {
  margin-right: 8px;
  width: 240px;
}
.el-card {
  margin-bottom: 20px;
}
.change {
  margin: 20px 0;
  .add {
    width: 86px;
    margin-left: 50px;
  }
}
.el-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.el-select,
.el-radio {
  margin-left: 8px;
}
</style>
        
      